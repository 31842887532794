<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG" v-if="Object.keys(store.EconomyOverview).length > 0">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview p-3" v-for="(value, key) in store.EconomyOverview">
                            <h4 class="green mb-4 text-center">{{ key }}</h4>
                            <div class="viewGDP position-absolute text-end">
                                <a @click="openPopup = !openPopup   "
                                    class="d-inline-flex overview-small-btn" href="javascript:void(0)"><img
                                        src="assets/images/lineGraph.svg" alt="Icon" title="Chart" /></a>
                                <p class="text-end mb-0 lighttext">{{ value[0].Unit }}</p>
                            </div>
                            <perfect-scrollbar class="scroll-area scrollOverview">
                                <ul class="listCountry mb-0">
                                    <li v-for="(list, index) in value">
                                        <a href="javascript:void(0)"
                                            class="d-flex justify-content-between align-items-center"
                                            @click="() => showCountryData(list.Country)">
                                            <span class="d-flex align-items-center"><img class="me-2" :src="
                                              'assets/images/country_flag/' +
                                              list.Country.toLowerCase() +
                                              '.webp'
                                            " alt="Flag" :title="list.Country" />{{ list.Country }}</span>{{ list.LatestValue }}
                                        </a>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-menu></economic-menu>
                            <economic-map v-if="Object.keys(store.EconomyOverview).length > 0"></economic-map>
                        </div>
                    </div>
                    <!-- 
                    <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade economy-overview-country ideaModal" :class="[{'show':openPopup}]"
        v-show="Object.keys(store.EconomyOverview).length > 0">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <div class="filterHeader d-flex align-items-center drop-1199">
                        <!-- <h2 class="mb-0">GDP</h2> -->
                        <div class="position-relative">
                            <a @click.prevent="dropdownMenu2 = !dropdownMenu2" href="javascript:void(0)"
                                class="pt-0 border-btn d-inline-flex align-items-center justify-content-between d-xl-none ms-2">
                                World
                                <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                            </a>
                            <ul class="dropdown_menu_animated onMbile pad-0 selectTab px-2 d-md-flex align-items-center mb-0"
                                :class="dropdownMenu2 ? 'show' : ''">
                                <li><a href="javascript:void(0)" :class="[{'active' : continent == 'World'}]"
                                        @click="continent = 'World';callCountryEconomyData()">World</a></li>
                                <li><a href="javascript:void(0)" :class="[{'active' : continent == 'G20'}]"
                                        @click="continent = 'G20';callCountryEconomyData()">G20</a></li>
                                <li><a href="javascript:void(0)" :class="[{'active' : continent == 'Europe'}]"
                                        @click="continent = 'Europe';callCountryEconomyData()">Europe</a></li>
                                <li><a href="javascript:void(0)" :class="[{'active' : continent == 'America'}]"
                                        @click="continent = 'America';callCountryEconomyData()">America</a></li>
                                <li><a href="javascript:void(0)" :class="[{'active' : continent =='Asia'}]"
                                        @click="continent = 'Asia';callCountryEconomyData()">Asia</a></li>
                                <li><a href="javascript:void(0)" :class="[{'active' : continent =='Australia'}]"
                                        @click="continent = 'Australia';callCountryEconomyData()">Australia</a></li>
                                <li><a href="javascript:void(0)" :class="[{'active' : continent =='Africa'}]"
                                        @click="continent = 'Africa';callCountryEconomyData()">Africa</a></li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <select class="form-select" name="groupIndicators" v-model="groupIndicators"
                            @change="callCountryEconomyData()">
                            <option v-for="(value, key) in static_vars.economyOverviewIndicators" :key="value">
                                {{ value }}
                            </option>
                        </select>
                    </div>
                    <div class="rightTab">
                        <button type="button" class="btn-bar" @click="changeChart()"
                            :class="[{'rotate' : direction}]">
                            <vue-feather size="20" type="bar-chart-2"></vue-feather>
                        </button>
                        <!-- <button type="button" class="btn-bar" @click="direction = 'vertical';drawVerticalChart()"
                            :class="[{'active' : direction == 'vertical'}]">
                            <vue-feather size="20" type="bar-chart-2"></vue-feather>
                        </button> -->
                        <button type="button" class="btn-close border"
                            @click="openPopup = false;direction = false"></button>
                    </div>
                </div>
                <!--  <div class="modal-body text-center py-4">
          <img
            src="assets/images/chart/chartBpi.svg"
            alt="chart"
            title="Chart"
          />
        </div> -->
                <div id="horizontalchart" style="width: 100%; height: 400px" v-show="!direction"></div>
                <div id="verticalchart" style="width: 100%; height: 420px" v-show="direction"></div>
            </div>
        </div>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import economicmenu from "./files/economy_menu";
import economicmap from "./files/economy_map";
import economicsearch from "./files/economy_search";
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as index from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            country: [],
            groupIndicators: "GDP",
            continent: "World",
            dropdownMenu2: false,
            openPopup: false,
            direction: false,
            EconomyIndicatorData : {}
        };
    },
    components: {
        bannerSlider: bannerslider,
        economicMenu: economicmenu,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,
    },
    methods: {
        changeChart(){
            if(this.direction){
                this.direction = false
                this.drawHorizontalChart()
            }else{
                this.direction = true
                this.drawVerticalChart()
            }
        },
        drawVerticalChart() {
            let data = [];
            if(this.EconomyIndicatorData[this.groupIndicators].length > 0){
                data = JSON.parse(JSON.stringify(this.EconomyIndicatorData[this.groupIndicators]))
                data.sort(function (a, b) {
                    return a.LatestValue - b.LatestValue;
                });
            }
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'verticalchart');
            d?.dispose();
            var root = am5.Root.new("verticalchart");

            root.interfaceColors.set("grid", am5.color(0xffffff));

            root.setThemes([am5themes_Animated.new(root)]);

            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX"
                })
            );
            var yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });

            var yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                   
                    maxDeviation: 0.3,
                    categoryField: "Country",
                    renderer: yRenderer,
                    opacity: 0.5,
                    fontSize: 12,
                    // tooltip: am5.Tooltip.new(root, {
                    //     labelText: "{Title} : {LatestValue}",
                    // }),
                })
            );
            chart.get("colors").set("colors", [
                am5.color(0x1DBD81),
            ]);


            var xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    // visible: false,
                    maxDeviation: 0.3,
                    valueField: "LatestValue",
                    //min: 0,
                    opacity: 0.5,
                    fontSize: 12,
                    renderer: am5xy.AxisRendererX.new(root, {})
                })
            );

            var series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Series 1",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: "LatestValue",
                    sequencedInterpolation: true,
                    categoryYField: "Country",
                    opacity: 0.5,
                    fontSize: 12,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueX}",
                        opacity: 0.5,
                        fontSize: 12,
                    })

                })
            );
            // series.columns.template.setAll({
            //     tooltipText: "{Country}: {LatestValue}",
            // });

            var columnTemplate = series.columns.template;

            // columnTemplate.adapters.add("fill", (fill, target) => {
            //     return chart.get("colors").getIndex(series.columns.indexOf(target));
            // });
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineX.set("visible", false);
            cursor.lineY.set("visible", false);
            yAxis.data.setAll(data);
            series.data.setAll(data);
            const _that = this;
            series.events.once("datavalidated", function (ev, target) {
                if (data.length > 10 && data.length >= 12) {
                    yAxis.zoomToCategories(data[data.length-1].Country, data[data.length-12].Country)
                }else if(data.length >= 10){
                     yAxis.zoomToCategories(data[data.length-1].Country, data[0].Country)
                } else {
                     yAxis.zoomToCategories(data[data.length-1].Country, data[0].Country)
                }
            })
            series.appear(1000);
            chart.appear(1000, 100);
        },
        drawHorizontalChart() {
            if(this.EconomyIndicatorData[this.groupIndicators].length > 0){
                this.EconomyIndicatorData[this.groupIndicators].sort(function (a, b) {
                    return b.LatestValue - a.LatestValue;
                });
            }
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'horizontalchart');
            d?.dispose();
            var root = am5.Root.new("horizontalchart");
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            root.interfaceColors.set("grid", am5.color(0xffffff));
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true
            }));
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
                rotation: -90,
                centerY: am5.p50,
                centerX: am5.p100,
                paddingRight: 15,
                opacity: 0.5,
                fontSize: 12,
            });

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "Country",
                renderer: xRenderer,
                opacity: 0.5,
                fontSize: 12,
                tooltip: am5.Tooltip.new(root, {})
            }));

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {})
            }));
            chart.get("colors").set("colors", [
                am5.color(0x1DBD81),
            ]);
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "LatestValue",
                sequencedInterpolation: true,
                categoryXField: "Country",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}",
                    opacity: 0.5,
                    fontSize: 12,
                })
            }));

            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
            // Set data
            if (this.EconomyIndicatorData[this.groupIndicators] && this.EconomyIndicatorData[this.groupIndicators].length) {
                const _that = this;
                xAxis.data.setAll(_that.EconomyIndicatorData[_that.groupIndicators]);
                series.data.setAll(_that.EconomyIndicatorData[_that.groupIndicators]);
                series.events.once("datavalidated", function (ev, target) {
                    if (_that.EconomyIndicatorData[_that.groupIndicators].length >= 10) {
                        xAxis.zoomToCategories(_that.EconomyIndicatorData[_that.groupIndicators][0].Country, _that.EconomyIndicatorData[_that.groupIndicators][9].Country)
                    } else {
                        xAxis.zoomToCategories(_that.EconomyIndicatorData[_that.groupIndicators][0].Country, _that.EconomyIndicatorData[_that.groupIndicators][_that.EconomyIndicatorData[_that.groupIndicators].length - 1].Country)
                    }
                })
                series.appear(1000);
                chart.appear(1000, 100);
            }
        },
        showCountryData(country) {
            // this.store.$patch({ selectedCountry: country });
            // this.store.$patch({ selectedIndicator: "GDP" });
            this.$router.push({ name: "gdp", query: { country: country } });
        },
        callCountryEconomyData() {
            this.$router.replace({
                path: this.$route.path,
                query: { region: this.continent, indicator: this.groupIndicators },
            });
            let formData = {
                indicator: [this.groupIndicators],
            };
            if (this.continent != "World") {
                formData["continent"] = this.continent;
            }
            // if (this.country.length > 0) {
            //   formData["country"] = this.country[0];
            // }
            this.store
                .getCountryEconomyData(formData, true)
                .then((response) => {
                    this.EconomyIndicatorData = response
                    if (Object.keys(response).length > 0) {
                        if (!this.direction) {
                            this.drawHorizontalChart()
                        } else {
                            this.drawVerticalChart()
                        }
                    }
                })
                .catch((err) => console.log(err.message));
        },
        setIndicator(indicator) {
            this.groupIndicators = indicator;
            this.callCountryEconomyData();
        },
    },
    created() {
        if (this.$route.query.region && this.$route.query.indicator) {
            this.continent = this.$route.query.region;
            this.groupIndicators = this.$route.query.indicator;
        } else {
            this.continent = "World";
            this.groupIndicators = "GDP";
        }
        this.callCountryEconomyData();
    },
};
</script>
<style>
    .ps {
        height: calc(100vh - 340px) !important;
    }
</style>