<template>
    <div class="mobScroll">
        <ul class="d-flex gdpRate align-items-center justify-content-end">
            <li :class="[{ active: $parent.groupIndicators == 'GDP' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('GDP')">GDP</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'GDP Annual Growth Rate' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('GDP Annual Growth Rate')">Growth</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'Interest Rate' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('Interest Rate')">Rate</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'Inflation Rate' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('Inflation Rate')">Inflation</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'Stock Market' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('Stock Market')">Markets</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'Currency' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('Currency')">Currencies</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'Corporate Tax Rate' }]">
                <a href="javascript:void(0)" @click="() => $parent.setIndicator('Corporate Tax Rate')">Tax</a>
            </li>
            <li :class="[{ active: $parent.groupIndicators == 'Coronavirus Cases' }]">
                <a class="pe-0" href="javascript:void(0)"
                    @click="() => $parent.setIndicator('Coronavirus Cases')">COVID-19</a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
